import { BehaviorSubject, Observable } from 'rxjs'
import { PopupService } from './PopupService'
import { Popup } from '@shared-types/popups/Interfaces'

export class RealPopupService extends PopupService {
  private helpPopup: BehaviorSubject<Popup> = new BehaviorSubject<Popup>({ content: null })
  public setPopup(popup: Popup, showPopup: boolean): void {
    if (showPopup === false) {
      return
    }
    this.helpPopup.next(popup)
  }
  public clearPopup(): void {
    this.helpPopup.next({ content: null })
  }
  public observePopup(): Observable<Popup> {
    return this.helpPopup
  }
}
